<div class="mt-4 d-xl-flex d-lg-flex d-md-grid d-sm-grid align-items-center gap-4">
    <!-- Product Images -->
    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
        <img [src]="data()?.img" [alt]="data()?.name" (error)="handleImageError($event)" class="img-fluid">
    </div>

    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
        <!-- Product Info -->
        <div class="product__info">

            <!-- Title -->
            <h3 class="product__name mb-1">{{ data()?.name }}</h3>

            <!-- Price -->
            <h4 class="product__price mt-1">{{ (data()?.price) | currency }}</h4>

            <!-- Producer -->
            <p class="product__producer m-1">
                <b>Producer: </b>
                {{ data()?.producer?.name }}
            </p>
            <!-- Deposit value -->
            <p class="product__fav-count m-1">
                <b>Deposit: </b>
                {{ (data()?.preOrder?.deposit || 0) | currency }}
            </p>
            <!-- Delivery -->
            <p class="product__fav-count m-1">
                <b>Delivery Week: </b>
                From
                <span class="fw-medium">{{ data()?.preOrder?._readableStartDeliveryDate?.readableString }}</span>
                to
                <span class="fw-medium">{{ data()?.preOrder?._readableEndDeliveryDate?.readableString }}</span>
            </p>

        </div>
    </div>
</div>
<!-- Description -->
<hr>
<div class="product-desc-text" [innerHTML]="depositConfig()?.message(data()?.preOrder?.deposit || 0)"></div>
<!-- <div class="product-desc-text text-muted mt-2" [innerHTML]="depositConfig()?.additionalInfo"></div> -->
<hr>
<small>
    <i class="text-success-emphasis">This product is only available for pre-order until
        <b>{{data()?.preOrder?._readableEndDate?.readableString}}.</b>
    </i>
</small>

<div class="d-flex justify-content-center gap-4 animated fadeInUp mt-5">
    <button (click)="confirmPreOrder()" type="button" class="col-4 btn btn-primary">Reserve Now</button>
    <button (click)="cancelPreOrder()" type="button" class="col-4 btn btn-outline-primary">Cancel</button>
</div>