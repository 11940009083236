import { BundleModifyTypes, PurchaseTypes } from "../app/shared/types/flows-config.types";
import { BundleEditionTypes } from "../app/shared/types/order.type";

const base_api = "https://farmboxtx.navego.tech";

export const environment = {
  production: false,
  apis: {
    v1: `${base_api}`,
    v2: `${base_api}/api/v2`,
    v3: `${base_api}/api/v3`,
    google: {
      places: 'AIzaSyBTHTviZ7QPtEjH29Jmmkp13RO8LPrxppM'
    },
    navego: {
      url: 'https://apis.navego.tech/api',
      key: 'dsfgrdf125fdffasd677rrfgr',
      clientNr: '9'
    },
    firebase: {
      apiKey: "AIzaSyAfRENRfEmFdT7uTlvvy8TW4qQ5Nk2r9PI",
      authDomain: "farmbox-fl.firebaseapp.com",
      projectId: "farmbox-fl",
      storageBucket: "farmbox-fl.appspot.com",
      messagingSenderId: "849555481013",
      appId: "1:849555481013:web:b844883841f64fa62539fc",
      measurementId: "G-9Q2JZ8YBK4"
    },
    fireauth: {
      email: 'javier.franco@eliostudios.co',
      password: '@N4v3g0.T3ch.2024**'
    },
    klaviyo: {
      publicApiKey: ''
    },
    maintenance: {
      url: 'https://ecommerce-configurations.navego.tech/api/v3/maintenance?url=farmboxtx-staging.com'
    },
    authorize: {
      APILoginID: '859vpT8YqF',
      PublicClientkey: '2789LLpawLnj4TJe2s5k7akamxnXS3aTq442sEs4vkU82JnTQ56e93fNuMUXmfC6'
    }
  },
  application: {
    angular: 'Angular 17.3.7',
    bootstrap: 'Bootstrap 5.3.2',
    fontawesome: 'Font Awesome 6.5.1',
    name: 'Farmbox Delivery',
    version: '1.0.0',
  },
  config: {
    bodyClass: 'app-lv',
    crypto: {
      key: '',
    },
    flows: {
      territories: false,
      membership: false,
      settings: {
        toHide: {
          membership: 'Membership'
        },
        profile: {
          hasPickupLocationsFlow: true
        },
        deliveryDayUpdates: {
          displayOnlyActiveReescheduledOrder: true
        }
      },
      specialMembership: {
        defaultRoute: '/order/custom-box/' + BundleEditionTypes.subscription,
        settings: {
          hideHeaderCategoriesNav: true
        },
      },
      order: {
        askForDonationOnSkip: false,
        showFreeDeliverySlider: true,
        voluntaryDonationFlow: false,
      },
      bundles: {
        modifyType: BundleModifyTypes.outOfOrder
      },
      shop: {
        validateMarketState: true
      },
      login: {
        tempResetPassword: true
      },
      purchase: {
        type: PurchaseTypes.anet
      },
      productDetail: {
        showMultipleImages: false
      }
    },
    filesVersionNumber: {
      logo: 1
    },
    friendReferral: {
      baseUrl: '3.19.44.52:8084/signup?utm_source=friend_referral&utm_medium=share&utm_campaign='
    },
    seo: {
      mainTitle: 'Local Organic Produce and Food Delivery in Philadelphia | Philly Foodworks',
      mainDescription: 'Discover Philly Foodworks: Local organic produce and food delivery in Philadelphia. Fresh, sustainable, and community-focused.',
      baseTitle: 'Philly Foodworks Local Organic Produce and Food Delivery',
      baseKeywords: ['local', 'organic', 'produce', 'food delivery', 'Philadelphia', 'Philly Foodworks'],
      type: 'website',
      baseURL: 'https://phillyfoodworks.com/'
    },
    contactEmail: 'info@farmboxdl.com',
    bundleCategoryId: 987
  },
};
