<div class="form-floating">
  <input #autocompleteInput type="text"
    [class.is-invalid]="!isLoadingContent() && (!hasDeliveryAddressCoverage() && isGoogleAddressFilled())"
    [class.is-valid]="!isLoadingContent() && hasDeliveryAddressCoverage()" class="form-control" id="address"
    (input)="changeAddress()" placeholder [(ngModel)]="queryString">
  <label for="address">Please enter your delivery address</label>
  @if (isLoadingContent()) {
  <mat-progress-bar mode="indeterminate" value="accent"></mat-progress-bar>
  } @else {
  @if (hasDeliveryAddressCoverage()) {
  <div class="valid-tooltip right-0">
    This address is in our delivery area!
  </div>
  } @else if (isGoogleAddressFilled()) {
  <div class="invalid-tooltip right-0">
    Oops! Your address isn't in our delivery zone.
  </div>
  }
  }
</div>
<small class="ms-1">
  <!-- <a href="https://www.google.com/maps/d/u/4/edit?mid=1BEjE30doE8rTuzSafIUjhlYjXoP4wsY&ll=39.61480540736804%2C-75.03865350000001&z=9"  target="_blank" class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
        Click here
    </a> -->
  <a href="javascript:void(0)"
    class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
    Click here
  </a>
  to view a map of our current delivery routes
</small>

<div class="d-grid gap-2 col-md-6 mx-auto mt-5">
  <button type="button" class="btn btn-primary" (click)="checkDeliveryAddressCoverage()">Check address</button>
  <small class="text-center mt-4">Already have an account?</small>
  <button type="button" class="btn btn-outline-primary" (click)="loginClickHandler()">Log in</button>
  <small class="text-center">
    <p>Don't have an account? <a href="/signup">Sign up here</a></p>
  </small>
</div>