<section>

  @if (productSubsInfo()?.subscribeAndcustomizeBox) {
  <p>How often would you want to receive your farmbox?</p>
  } @else {
  <p>When would you like to receive your subscription item(s)?</p>
  }

  <mat-form-field class="rounded-selector mb-3 d-flex" subscriptSizing="dynamic">
    <mat-label>Frequency </mat-label>
    <mat-select (selectionChange)="onChangeFrequency($event)" [value]="selectedFrequency?.id">
      @for(freq of frequencies(); track $index){
      <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (!productSubsInfo()?.subscribeAndcustomizeBox) {
  <mat-checkbox [checked]="true" class="mb-3" (change)="initiateSubscriptionImmediatly.set($event.checked)">
    Initiate my subscription immediately
  </mat-checkbox>

  @if (!initiateSubscriptionImmediatly()) {
  <mat-form-field class="w-100 mb-3">
    <mat-label>Start subscription date</mat-label>
    <input matInput [min]="productSubsInfo()?.minDate || currentDate" [matDatepicker]="startSubscription"
      [matDatepickerFilter]="dateFilter" disabled (dateChange)="onDateChange($event)">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="startSubscription"></mat-datepicker-toggle>
    <mat-datepicker [yPosition]="'below'" #startSubscription disabled="false"></mat-datepicker>
  </mat-form-field>
  }
  }

  <hr>

  <div class="card-footer p-0 mt-1 mt-auto">
    <div class="row">
      @if (productSubsInfo()?.subscribeAndcustomizeBox) {
      <div class="col">
        <button type="button" (click)="continue()"
          [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
          class="btn btn-secondary d-flex gap-2 align-items-center">
          Subscribe & continue
        </button>
      </div>
      } @else {
      <div class="col">
        <button type="button" (click)="cancel()" class="btn btn-outline-secondary d-flex gap-2 align-items-center">
          Cancel
        </button>
      </div>
      <div class="col">
        <button type="button" (click)="subscribe($event)"
          [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
          class="btn btn-secondary d-flex gap-2 align-items-center">
          Subscribe
        </button>
      </div>
      }
    </div>
  </div>

</section>
