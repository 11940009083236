import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseCrudService {

  private firestore = inject(AngularFirestore);
  private fireauth = inject(AngularFireAuth);
  private storage = inject(AngularFireStorage);

  constructor() {
    this.fireauth.signInAnonymously().then(
      () => {
        console.info('FIREBASE AUTHENTICATION SUCCESFULL');
      }
    )
  }

  getHeroVideo() {
    return this.storage.ref('MEF Banner Video V2.mov').getDownloadURL();
  }

  // Método para obtener todos los documentos de una colección
  getAll(collection: string): Observable<any[]> {
    return this.firestore.collection(collection).snapshotChanges().pipe(
      map(actions => actions.map((a: any) => {
        const data: any = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  // Método para obtener un documento por su ID de una colección
  getById(collection: string, id: string): Observable<any> {
    return this.firestore.collection(collection).doc(id.toString()).valueChanges();
  }

  // Método para agregar un nuevo documento a una colección
  add(collection: string, docId: string | number, data: any) {
    return this.firestore.collection(collection).doc(docId.toString()).set(data);
  }

  addWithoutDocId(collection: string, data: any) {
    return this.firestore.collection(collection).add(data);
  }

  // Método para actualizar un documento existente en una colección
  async update(collection: string, id: string | number, data: any): Promise<void> {

    const exists = await this.existsDoc(collection, id.toString());
    if (!exists)
      return Promise.resolve();

    return this.firestore.collection(collection).doc(id.toString()).update(data);
  }

  async updateSubkeys(payload: FirebaseUpdateSubKeys): Promise<void> {

    const { collection, docId, updateData } = payload;

    const exists = await this.existsDoc(collection, docId);
    if (!exists)
      return Promise.resolve();

    return this.firestore
      .collection(collection)
      .doc(docId.toString())
      .update(updateData);
  }

  async updateSubkey(payload: FirebaseUpdateSubKey): Promise<void> {

    const { collection, docId, subKeyPath, newValue } = payload

    const exists = await this.existsDoc(collection, docId);
    if (!exists)
      return Promise.resolve();

    const updateData: any = {};
    updateData[subKeyPath] = newValue;  // Creamos un objeto con la subclave y su nuevo valor
    return this.firestore.collection(collection).doc(docId.toString()).update(updateData);
  }

  existsDoc(collection: string, docId: string): Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

      try {

        this.firestore
          .collection(collection)
          .doc(docId)
          .get()
          .pipe(
            tap(snapshot => resolve(snapshot.exists))
          )
          .subscribe();

      } catch (error) {
        reject(error);
      }
    });
  }

  // Método para eliminar un documento de una colección
  delete(collection: string, id: string): Promise<void> {
    return this.firestore.collection(collection).doc(id.toString()).delete();
  }
}

export interface FirebaseUpdateSubKey {
  collection: string;
  docId: string;
  subKeyPath: string;
  newValue: any;
}
export interface FirebaseUpdateSubKeys {
  collection: string;
  docId: string;
  updateData: any;
}
