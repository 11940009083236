import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { filter, tap } from "rxjs";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { LocalStorageService } from "../../../shared/local-storage.service";
import { LOCALSTORAGE_KEYS } from "../../../shared/constants/databases";
import { Session } from "../../../shared/types/session.type";
import { ApiResponse } from "../../../shared/common/types";
import { DeliveryInformation, FormattedDeliveryInformation, UpdateDeliveryInfoPayload } from "./intarfaces";
import { NotificationService } from "../../../shared/notification/notification.service";
import { formatDateToReadableString } from "../../../shared/utils/formatting";
import { SettingsService } from "../../settings.service";

@Injectable({
    providedIn: 'root'
})
export class DeliveriesService {
    private apiService = inject(ApiService);
    private localStorageService = inject(LocalStorageService);
    private notificationService = inject(NotificationService);
    #settingsService = inject(SettingsService);

    deliveryZoneInfo: WritableSignal<FormattedDeliveryInformation | null> = signal(null);

    private endpoints = {
        deliveryZone: '/account/delivery-info'
    }

    getDeliveryZoneInfo() {
        const params: RequestHandlerParams = {
            method: 'GET',
            endpoint: this.endpoints.deliveryZone,
            apiV3: true
        };

        return this.apiService.handleRequest<ApiResponse<DeliveryInformation>>(params).pipe(
            filter((response) => !!response?.data),
            tap((response) => this.setUpDeliveryInfoResponse(response.data)),
        );
    }

    private setUpDeliveryInfoResponse(delivery: DeliveryInformation) {
        const currentSessionInfo: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
        if (currentSessionInfo) {
            const newSessionInfo: Session = { ...currentSessionInfo, deliveryInfo: delivery }
            this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, newSessionInfo);
        }
        let formattedDelivery: FormattedDeliveryInformation = {
            ...delivery,
            orderCutoffExceeded: delivery.orderCutoffExceeded ? {
                ...delivery.orderCutoffExceeded,
                _readableDeliveryDate: formatDateToReadableString(delivery.orderCutoffExceeded.deliveryDate),
            } : null,
            orderRescheduled: delivery.orderRescheduled?.map(o => {
                return {
                    ...o,
                    _readablePrevDeliveryDate: formatDateToReadableString(o.prevDeliveryDate),
                    _readableNewDeliveryDate: formatDateToReadableString(o.newDeliveryDate)
                }
            }) ?? []
        };
        formattedDelivery.deliveryWindowWithoutDay = this.setUpDeliveryWindowWithoutDay(delivery);
        formattedDelivery.readeableDeliveryDate = formatDateToReadableString(formattedDelivery.deliveryDate).readableString;
        this.deliveryZoneInfo.set(formattedDelivery);
    }

    private setUpDeliveryWindowWithoutDay(deliveryInfo: DeliveryInformation): string {
        const deliveryWindow = deliveryInfo?.deliveryWindow.toLowerCase() || null;
        if (!deliveryWindow) return '';
        const windowWithoutDay = deliveryWindow.substring(deliveryInfo.deliveryDay.length).trim();
        return `${windowWithoutDay.charAt(0).toUpperCase()}${windowWithoutDay.slice(1)}`;
    }

    updateDeliveryInfo(body: UpdateDeliveryInfoPayload) {
        this.#settingsService.setIsWaitingForAPIResponse(true);
        const params: RequestHandlerParams = {
            method: 'PUT',
            endpoint: this.endpoints.deliveryZone,
            apiV3: true,
            body
        };

        return this.apiService.handleRequest<ApiResponse<DeliveryInformation>>(params).pipe(
            tap(() => this.#settingsService.setIsWaitingForAPIResponse(false)),
            filter((response) => !!response?.data),
            tap((response) => {
                this.notificationService.show({ text: response.message, type: 'success' });
                this.setUpDeliveryInfoResponse(response.data);
            }),
        )
    }
}