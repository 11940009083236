export enum ModalContentTypes {
  ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED',
  BECOME_A_MEMBER = 'BECOME_A_MEMBER',
  BOX_DETAIL = 'BOX_DETAIL',
  BUY_AGAIN = 'BUY_AGAIN',
  CHANGE_CREDENTIALS = 'CHANGE_CREDENTIALS',
  CHECK_ADDRESS = 'CHECK_ADDRESS',
  CONFIRMATION = 'CONFIRMATION', // This one option is for both "Cancel subscription" and "Cancel membership" modal options and for all of these that only need a yes or not confirmation modal
  DONATION = 'DONATION',
  UPDATE_DONATION = 'UPDATE_DONATION',
  CREATE_DONATION = 'CREATE_DONATION',
  UPDATE_TIP = 'UPDATE_TIP',
  CREATE_TIP = 'CREATE_TIP',
  DONATION_SKIPPABLE = 'DONATION_SKIPPABLE',
  HOLD_UP_UPDATE_SUBSCRIPTION = 'HOLD_UP_UPDATE_SUBSCRIPTION',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  WELCOME_MESSAGE = 'WELCOME_MESSAGE',
  SEND_INFORMATION = 'SEND_INFORMATION',
  MEMBERSHIP_CSA_DEPOSITS = 'MEMBERSHIP_CSA_DEPOSITS',
  PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION',
  SKIP_DELIVERY = 'SKIP_DELIVERY',
  PENDING_PAYMENT_METHOD = 'PENDING_PAYMENT_METHOD',
  PENDING_PAYMENT_METHOD_NO_SIGNUP = 'PENDING_PAYMENT_METHOD_NO_SIGNUP', // For users who don't have a registered payment method but don't need to go through the signup flow.
  PROSPECT = 'PROSPECT',
  TIP = 'TIP',
  DELETE_TIP_AND_DONATION = 'DELETE_TIP_AND_DONATION',
  WELCOME_BACK_LOGIN_OR_ANOTHER_EMAIL = 'WELCOME_BACK_LOGIN_OR_ANOTHER_EMAIL',
  CARD_UPDATE_IN_PROGRESS = 'CARD_UPDATE_IN_PROGRESS',
  BUY_AGAIN_WARNING = 'BUY_AGAIN_WARNING', // This one is for showing to users when they try to "buy again" from order history but one or more products can't be added to their order.
  PAST_CUT_OFF = 'PAST_CUT_OFF',
  CHECK_SUBMIT_ORDER = 'CHECK_SUBMIT_ORDER',
  SELECT_DELIVERY_DAY = 'SELECT_DELIVERY_DAY',
  PRODUCT_SUBSCRIPTION = 'PRODUCT_SUBSCRIPTION',
  SUBSCRIPTION_NEXT_DELIVERY = 'SUBSCRIPTION_NEXT_DELIVERY',
  PREORDER_PRODUCT = 'PREORDER_PRODUCT',
  DISLIKE_PRODUCT_CONFIRMATION = 'DISLIKE_PRODUCT_CONFIRMATION',
  BUNDLES_SUBSCRIPTION = 'BUNDLES_SUBSCRIPTION',
  RESCHEDULED_ORDER = 'RESCHEDULED_ORDER',
  RESUME_SUBSCRIPTION = 'RESUME_SUBSCRIPTION',
  BUNDLE_SUBSTITUTION = 'BUNDLE_SUBSTITUTION',
  AUTH_NET_PURCHASE = 'AUTH_NET_PURCHASE',
  NMI_PURCHASE = 'NMI_PURCHASE'
}
