<div class="product-card-addon" [class.selected]="product().isSelectedAsReplacement"
    (click)="onSelectItem.emit(product())">
    <a class="product-card-addon__img">
        <img [src]="product()?.img || 'assets/images/product-placeholder.webp'" alt="image" />
    </a>
    <div class="product-card-addon__detail">

        <!-- Name and dislike -->
        <div class="d-flex justify-content-between">
            <!-- Product Name -->
            <h4 class="title" ngbTooltip="{{product()?.name}}">{{ product()?.name }}</h4>

        </div>
        <!-- Package -->
        <span class="meta">{{ product()?.attribute?.name || product()?.packageName }}</span>
        <!-- Display -->
        <span class="meta">{{ product()?.display }}</span>
        <!-- Price -->
        @if ((!isLimitedUser() || isLockedBundle()) && !removable()) {
        <h4 class="price">{{ product()?.price | currency }}</h4>
        }

    </div>
    <!-- Tags Icons -->
    <ul class="product-card__tags">
        @for (tag of product()?.tags || []; track $index) {
        <li>
            @if (tag.icon) {
            <img src="{{tag.icon}}" [alt]="tag.name" [ngbTooltip]="tag.name" />
            } @else {
            <span class="material-symbols-outlined" [ngbTooltip]="tag.name">
                info
            </span>
            }
        </li>
        }
    </ul>
</div>