export const checkValidNumber = (inputValue: number): boolean => {
  const regularExpression = /^\d+$/;
  return regularExpression.test(inputValue.toString());
}

export const checkValueChange = (inputValue: number, previousValue: number, isValueChanged?: boolean): boolean => {
  // if (isValueChanged) 
  //   return true

  return inputValue != previousValue;
}

// private checkValueChange(inputValue: number): void {
//   if (this.isValueChanged) return
//   return inputValue != this.previousValue;
//   // this.isValueChanged = inputValue != this.previousValue;
// }

// isInputValid!: boolean;
// isValueChanged!: boolean;
// wasValueHigher10!: boolean;
// previousValue!: number;
// newQuantity: WritableSignal<any> = signal(1);

// product: any = input(null, {
//     alias: 'product',
//     transform: (product: any) => {
//       this.previousValue = product.quantity;
//       this.checkValueChange(product.quantity);
//       this.newQuantity.set(product.quantity)
//       if (product.quantity >= 10)
//         this.wasValueHigher10 = true;

//       return product;
//     }
//   })
// productKey: any = input(null, {
//     alias: 'productKey'
// })

// ngOnInit(): void {
//     this.isInputValid = true;
//     this.isValueChanged = false;
//   }

//   private validateInput(inputValue: number): void {
//     const regex = /^\d+$/;
//     this.isInputValid = regex.test(inputValue.toString());
//   }

//   private checkValueChange(inputValue: number): void {
//     if (this.isValueChanged) return
//     this.isValueChanged = inputValue != this.previousValue;
//   }

// onKeyUp(event: any): void {
//     const { value } = event?.target
//     this.validateInput(+value);
//     this.checkValueChange(+value);
//     if (!this.isInputValid)
//       return

//     if (+value >= 10)
//       this.wasValueHigher10 = true;

//     this.newQuantity.set(+value)
//   }

// onQuantityChange(target: any) {
//     if (+target?.value >= 10)
//       this.wasValueHigher10 = true;

//     this.newQuantity.set(+target?.value);
//     this.validateInput(+target?.value);
//     this.checkValueChange(+target?.value);

//     if (+target?.value <= 9)
//       this.updateQuantityInFirebase(this.newQuantity())
//   }

// updateQuantityInFirebase(quantity: number | null) {
//     if (!quantity)
//       return
    
//     this.orderService.updateFirebaseProduct(this.productKey(), {...this.product(), quantity}, this.signalsStoreService.firebaseOrder())
//     this.wasValueHigher10 = false;
//     this.isValueChanged = false;
//   }

// removeCartProductInFirebase() {
//     this.orderService.removeFirebaseProduct(this.productKey(), this.product(), this.signalsStoreService.firebaseOrder())
//   }

