<div class="modal-action-row">
  <mat-checkbox (change)="handleCheckboxChange($event.checked)">
    Don't show me this again
  </mat-checkbox>
</div>

<div class="modal-action-row has-btn-2">
  <button type="button" class="btn btn-outline-primary" (click)="close()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="close(true)">
    Confirm
  </button>
</div>