import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, Signal, WritableSignal, computed, inject, output, signal } from '@angular/core';
import { DeliveryAddress } from '../signup.types';
import { AddressService } from '../../../shared/address.service';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { ArrayOptionsItem, DeliveryDay, DeliveryOptionsResponseItem, PickUpOption } from '../../../shared/types/account.types';
import { AccountService } from '../../../shared/account.service';
import { AutocompleteInputComponent } from '../autocomplete-input/autocomplete-input.component';
import { formatDateToReadableString } from '../../../shared/utils/formatting';

@Component({
  selector: 'app-check-address-signup',
  standalone: true,
  providers: [
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatRadioModule,
    AutocompleteInputComponent,
  ],
  templateUrl: './check-address-signup.component.html',
  styleUrl: './check-address-signup.component.scss'
})
export class CheckAddressSignupComponent implements OnInit {
  private addressService = inject(AddressService);
  private signalsStoreService = inject(SignalsStoreService);
  private accountService = inject(AccountService);

  @Input() stepper!: MatStepper;
  @Input() firstStep!: MatStep;
  @Output() onNextStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() deliveryAddressChanged = new EventEmitter<WritableSignal<Partial<DeliveryAddress>>>();
  @Output() selectedDayChanged = new EventEmitter<any>();
  @Output() deliveryOptionChanged = new EventEmitter<any>();

  out_deliveryAddress = output<DeliveryAddress>();
  out_isGoogleAddressFilled = output<boolean>();
  out_hasDeliveryAddressCoverage = output<boolean>();

  validSelectedAddress = signal<any>(null);

  deliveryAddress: WritableSignal<Partial<DeliveryAddress>> = signal({
    additionalAddress: '',
    address: '',
    city: '',
    deliveryNotes: '',
    stateCode: '',
    zipCode: '',
  })

  deliveryOption: any[] = [];
  pickUpOptions: PickUpOption[] = [];
  selectedPickUpDeliveryDays: DeliveryDay[] = [];
  zoneId: number = 0;
  selectedDeliveryWindow: DeliveryDay[] = [];
  deliveryOptionSelected: any = signal(false);
  isGoogleAddressFilled = signal(false);
  isLoadingContent: any = this.addressService.isLoading;
  hasDeliveryAddressCoverage: any = signal(false);
  selectedDeliveryDay: WritableSignal<DeliveryOptionsResponseItem | null> = signal(null);
  selectedPickUpOption: WritableSignal<PickUpOption | null> = signal(null);
  deliveryOptions: DeliveryOptionsResponseItem[] = [];
  selectedDeliveryTimeWindow: any = signal(null);
  selectedPickUpDeliveryWindow: any = signal(null);
  selectedPickUpDeliveryDay: any = signal(null);
  timeSet: Signal<ArrayOptionsItem[]> = computed(() => this.setUpTimeSet());
  pickUpLocations: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.updateDeliverySelection();
    this.accountService.getAllCustomerTypes().subscribe(deliveryOption => {
      this.deliveryOption = deliveryOption.data;
      this.deliveryOptionSelected = signal(this.deliveryOption[0]);
    });
  }

  private getDeliveryOptions() {
    this.accountService
      .getDeliveryOptions(this.addressService.navegoAddress()?.zoneId)
      .subscribe({
        next: (options: DeliveryOptionsResponseItem[]) => {

          this.deliveryOptions = options;

          if (this.deliveryOptions.length) {

            if (this.deliveryOptions[0].deliveryDate) {
              const { dayName, month, day } = formatDateToReadableString(this.deliveryOptions[0].deliveryDate);
              this.deliveryOptions[0].formattedDate = `${dayName}, ${month} ${day}`;
            }

            this.selectedDeliveryDay.set(this.deliveryOptions[0]);
            this.selectedDeliveryTimeWindow.set(null)
          }
        }
      });
  }

  updateDeliveryAddress(newValue: any) {
    this.deliveryAddress.update((value) => ({ ...value, ...newValue }))
    this.out_deliveryAddress.emit({ ...this.signalsStoreService.googleAddress(), ...this.deliveryAddress() });
  }

  setSelectedDeliveryDay(event: MatSelectChange) {
    const option = this.deliveryOptions.find(o => o.id === event.value);
    if (option) {
      this.selectedDeliveryDay.set(option);
      this.selectedDeliveryTimeWindow.set(null)
    }
  }

  setSelectedPickUpOption(event: any) {
    this.selectedPickUpDeliveryWindow.set(false)
    this.selectedPickUpDeliveryDay.set(false)
    const option = this.pickUpOptions.find(o => o.id === event.value);
    if (option) {
      this.selectedPickUpOption.set(option);
      this.zoneId = option.id
      this.selectedPickUpDeliveryDays = option.deliveryDays;
      this.selectedDeliveryWindow = option.deliveryDays;
    }
  }

  setSelectedPickUpDeliveryDays(event: MatSelectChange) {
    const option = this.selectedPickUpDeliveryDays.find(o => o.id === event.value);
    if (option) {
      this.selectedPickUpDeliveryDay.set([{ deliveryDay: option.id, zoneId: this.zoneId }]);
    }
  }

  setSelectedPickUpDeliveryWindow(event: any) {
    const option = [{ id: -1, name: "No preferences" }, this.selectedDeliveryWindow.find(o => o.id === event.value)]
    if (option && this.selectedPickUpDeliveryDay != null) {
      this.selectedPickUpDeliveryWindow.set(option)
    }
  }

  public updateDeliverySelection() {
    this.updateDeliveryAddress({ 'deliveryOptionSelected': this.deliveryOptionSelected() });
    this.selectedPickUpOption.set(null);
    this.selectedPickUpDeliveryWindow.set(false);
    this.selectedPickUpDeliveryDay.set(false);
    this.validSelectedAddress.set(null);
    if (this.pickUpLocations) {
      this.deliveryOptionSelected = signal(this.deliveryOption[1]);
      const element = document.getElementById('mat-radio-group');
      element?.click();
    }
  }

  private setUpTimeSet() {
    const selectedDay = this.selectedDeliveryDay();
    return (selectedDay && selectedDay.timeSet && selectedDay.timeSet.length) ? [{ id: -1, name: "No preferences" }, ...selectedDay.timeSet] : [];
  }

  continue() {
    this.firstStep.completed = true
    this.deliveryAddressChanged.emit(this.deliveryAddress);
    if (this.selectedDeliveryDay()) {
      this.selectedDayChanged.emit(this.selectedDeliveryDay());
    } else {
      this.selectedDayChanged.emit(this.selectedPickUpDeliveryDay());
    }
    this.deliveryOptionChanged.emit(this.deliveryOptionSelected());
    this.onNextStep.emit(this.firstStep);
  }

  onValidDeliveryAddressSelected(e: any) {
    this.validSelectedAddress.set(e);
    if (!!this.addressService.navegoAddress()) {
      this.getDeliveryOptions();
      this.out_hasDeliveryAddressCoverage.emit(true);
      this.out_isGoogleAddressFilled.emit(true);
    }

    this.updateDeliveryAddress({ zipCode: e?.zipCode ?? '' });
  }
}
