@if (items().length) {
<div class="row bundle-substitution-container">
    @for (bundleItem of items(); track $index) {
    <div class="col-sm-12 col-md-6 col-lg-3 animated fadeIn custom-box">
        <app-bundle-substitution-product-card [removable]="true" [product]="bundleItem" [isLimitedUser]="false"
            [isLockedBundle]="false" (onSelectItem)="onSelectItem($event)">
        </app-bundle-substitution-product-card>
    </div>
    }
</div>

<div class="d-flex justify-content-center gap-4 animated fadeInUp">
    <button type="button" class="btn btn-primary" [disabled]="!selectedItem()" (click)="addProductToFavorites()">Confirm</button>
</div>
}