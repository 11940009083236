import { Injectable, signal } from '@angular/core';
import { checkValidNumber } from './utils/product-cards';

@Injectable({
  providedIn: 'root'
})
export class ProductCardService {
  wasValueHigher10 = signal(false);
  newQuantity = signal(1)

  constructor() { }

  onKeyUp(value: any): void {
    if (!checkValidNumber(+value)) return;
    if (+value >= 10) this.wasValueHigher10.set(true);

    this.newQuantity.set(+value);
  }

  onChangeQuantitySelect(value: any): void {
    if (+value >= 10) this.wasValueHigher10.set(true);

    this.newQuantity.set(+value);
  }

  isQuantityHighOrPreviouslyHigh(): boolean {
    return this.wasValueHigher10() || this.newQuantity() >= 10 || (this.newQuantity() >= 10 && this.wasValueHigher10());
  }
}

export interface CurrentCustomItem {
  attribute: any;
  category: any;
  id: number;
  img: string;
  name: number;
  price: number;
  quantity: number;
  subCategory: any;
  variant: any;
}