export interface ReadableDate {
    month: string;
    day: string;
    year: number;
    dayName: string;
    readableString: string;
    monthNumber: string;
    dayNumber: string;
    mmddyyyyFormat: string;
}

export const convertToYYYYMMDD = (dateString: string): string => {
    if (!dateString) return '';
    const parts = dateString.split('/'); // Dividir la cadena por "/"
    const formattedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;

    return formattedDate;
}

export const formatDateToYYYYMMDD = (date: any): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son indexados desde 0
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const formatDateToReadableString = (dateString: string): ReadableDate => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(`${dateString}T00:00:00`);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayName = daysOfWeek[date.getDay()];

    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';

    } else if (day === 2 || day === 22) {
        suffix = 'nd';
    } else if (day === 3 || day === 23) {
        suffix = 'rd';
    }

    const monthNumber = (((monthIndex + 1) < 10) ? `0${(monthIndex + 1)}` : (monthIndex + 1)).toString();
    const dayNumber = (day < 10 ? `0${day}` : day).toString();
    const formattedDate: ReadableDate = {
        month: months[monthIndex],
        monthNumber ,
        day: day + suffix,
        dayNumber,
        year,
        dayName,
        readableString: `${months[monthIndex]} ${day}${suffix}, ${year}`,
        mmddyyyyFormat: `${monthNumber}/${dayNumber}/${year}`
    }
    // `${formattedDate.month} ${formattedDate.day}, ${formattedDate.year}`;
    return formattedDate;
}

export const addDaysToDate = (dateString: string, days: number): string => {
    let date = new Date(`${dateString}T00:00:00`);
    const newDate = new Date(date.setDate(date.getDate() + days));
    return newDate.toISOString().split('T')[0];
}

export const getWeekNumberFromDate = (date: Date): number => {
    const dowOffset = 0; //default dowOffset to zero
    var newYear = new Date(date.getFullYear(), 0, 1);
    var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((date.getTime() - newYear.getTime() -
        (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1;
        if (weeknum > 52) {
            let nYear = new Date(date.getFullYear() + 1, 0, 1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
        }
    }
    else {
        weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;
}

export const getDateFromDayAndWeek = (dayNumber: number, weekNumber: number): Date => {
    // Create a new date for the first day of the year
    var date = new Date(new Date().getFullYear(), 0, 1);
    // Adjust to get the specific day of the specific week
    date.setDate(date.getDate() + (weekNumber - 1) * 7 + (dayNumber - 1));
    return date;
}

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const formatCurrency = (value: number | string): string => {
    if (!value)
        return '$0.00'; // Si el valor es vacío, devolver '0.00'
    
    if(typeof value === "number")
        value = value.toFixed(2)

    // Obtener la parte entera y decimal
    const decimalPart = value.slice(-2); // Parte decimal
    let integerPart = value.substring(0, value.length - 2) || '0'; // Parte entera

    if( parseInt(integerPart) === 0 )
        integerPart = '0'
    // else if ( parseInt(integerPart) <= 9 )
    //     integerPart = parseInt(integerPart).toString()
    else
        integerPart = parseInt(integerPart).toString()

    // Agregar comas cada tres dígitos en la parte entera
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Concatenar parte entera y parte decimal
    return '$' + formattedIntegerPart + '.' + decimalPart;
}

export const formatCurrencyToAmount = (value: number | string): number => {
    const formattedAmount = formatCurrency(value.toString().replace(/[^\d]/g, ''))
    const amount = parseFloat(formattedAmount.replace(/[$,]/g, ''))

    return amount
}

export const formatSubscriptionDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // El mes está basado en cero, por eso se suma 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}