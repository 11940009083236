import { Injectable, Renderer2, inject, Inject, RendererFactory2, PLATFORM_ID } from '@angular/core';
import { Params, Router } from "@angular/router";
import { LocalStorageService } from "./shared/local-storage.service";
import { LOCALSTORAGE_KEYS } from "./shared/constants/databases";
import { MarketingReferral } from "./shared/types/common.types";
import { isPlatformBrowser, Location } from '@angular/common';
import { catchError, filter, of, take, tap } from 'rxjs';
import { Maintenance } from './shared/common/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MAINTENANCE_CONF } from './shared/constants/maintenance';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private localStorageService = inject(LocalStorageService);
  private rendererFactory = inject(RendererFactory2);
  private httpClient = inject(HttpClient);
  private router = inject(Router);
  private location = inject(Location)
  private renderer: Renderer2;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  addClassToBody(className: string) {
    if (!isPlatformBrowser(this.platformId)) return
    this.renderer.addClass(document.body, className);
  }

  checkMarketingReferral(params: Params) {
    if (!params['utm_source'] || !params['utm_medium'] || !params['utm_campaign']) return;

    this.localStorageService.set(LOCALSTORAGE_KEYS.MARKETING_REFERRAL, {
      utmCampaign: params['utm_campaign'],
      utmMedium: params['utm_medium'],
      utmSource: params['utm_source'],
    } as MarketingReferral);
  }

  getMaintenanceState(reload: boolean = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    this.httpClient.get<Maintenance>(environment.apis.maintenance.url, { headers }).pipe(
      catchError((error) => {
        console.log('There was an error trying to get the maintenance mode status', error);
        return of(null);
      }),
      filter((result): result is Maintenance => result !== null),
      take(1),
      tap(res => {
        const previousState: boolean = JSON.parse(JSON.stringify(this.localStorageService.get(LOCALSTORAGE_KEYS.MAINTENANCE_MODE)));
        this.localStorageService.set(LOCALSTORAGE_KEYS.MAINTENANCE_MODE, res?.active || false);
        this.localStorageService.set(LOCALSTORAGE_KEYS.MAINTENANCE_SCHEDULED, res?.schedule || null);
        this.localStorageService.set(LOCALSTORAGE_KEYS.MAINTENANCE_TITLE, res?.title || MAINTENANCE_CONF.title);
        this.localStorageService.set(LOCALSTORAGE_KEYS.MAINTENANCE_MESSAGE, res?.message || MAINTENANCE_CONF.message);
        if (res.active && !previousState) {
          if (reload) {
            this.location.replaceState('/maintenance');
            window.location.reload();
          } else {
            this.router.navigate(['/maintenance']);
          }
        }
        else if (!res.active && previousState)
          this.router.navigate(['/home']);
      }),
    ).subscribe();
  }
}
