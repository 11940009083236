import { Component, inject, input, InputSignal, signal, WritableSignal } from '@angular/core';
import { BundleItems } from '../../../product/product.types';
import { CommonModule } from '@angular/common';
import { BundleSubstitutionProductCardComponent } from './bundle-substitution-product-card/bundle-substitution-product-card.component';
import { ProductsService } from '../../../product/products.service';
import { NotificationService } from '../../notification/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bundle-substitution',
  standalone: true,
  imports: [CommonModule, BundleSubstitutionProductCardComponent],
  templateUrl: './bundle-substitution.component.html',
  styleUrl: './bundle-substitution.component.scss'
})
export class BundleSubstitutionComponent {

  #productsService = inject(ProductsService);
  #notificationService = inject(NotificationService);
  #activeModal = inject(NgbActiveModal);

  items: InputSignal<BundleItems[]> = input<BundleItems[]>([]);
  selectedItem: WritableSignal<BundleItems | null> = signal(null);

  onSelectItem(item: BundleItems) {
    this.selectedItem.set(item);
    for (const element of this.items()) {
      element.isSelectedAsReplacement = (element.id === item.id && item.packageId === element.packageId)
    }
  }

  addProductToFavorites() {
    const item = this.selectedItem();
    const variantId = item?.id || null;
    if (!item || !variantId) return this.#notificationService.show({ text: 'You should select one product from the list', type: 'warning' });
    this.#productsService
      .setFavorite(variantId, false)
      .subscribe(() => this.#activeModal.close(item));
  }

}
