export const depositConfig = {
    fee_deposit: {
        title: 'Reservation Fee Deposit',
        message: (deposit: number) => `An additional fee deposit of <b>$${deposit.toFixed(2)}</b> is required for each unit of this seasonal product to secure your reservation. Please note that this deposit is <b>non-refundable</b> if you cancel your order.`,
        additionalInfo: 'This deposit secures your pre-order and will <b>not</b> be deducted from your final payment.'
    },
    advance_deposit: {
        title: 'Advanced Payment Deposit',
        message: (deposit: number) => `An advanced deposit of <b>$${deposit.toFixed(2)}</b> is required per unit, and <b>will be deducted from your total order amount on the week of delivery.`,
        additionalInfo: 'This deposit <b>will be deducted from your final payment</b> but will not be refunded if the order is canceled.'
    }
}
