import { Component, computed, inject } from '@angular/core';
import { OrderService } from '../../order.service';
import { BasicBundleInfo } from '../../types/order.type';
import { Router } from '@angular/router';
import { EmptyMessageComponent } from '../../empty-message/empty-message.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bundles-subscription',
  standalone: true,
  imports: [
    EmptyMessageComponent
  ],
  templateUrl: './bundles-subscription.component.html',
  styleUrl: './bundles-subscription.component.scss'
})
export class BundlesSubscriptionComponent {

  //#region Services

  #orderService = inject(OrderService);

  #router = inject(Router);

  #activeModal = inject(NgbActiveModal);

  //#endregion

  //#region Properties

  bundles = computed(() => this.#orderService.getBundlesLikeSubscriptions());

  //#endregion

  //#region Methods

  goToCustomBox(bundle: BasicBundleInfo) {
    this.#activeModal.close();
    this.#router.navigate([`/shop/custom-box/subscription/${bundle.id}`]);
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  //#endregion
}
