<section class="d-flex flex-column gap-4">

  @for (bundle of bundles(); track bundle) {
  <div class="d-flex gap-3 bundle">

    <img [src]="bundle.image ?? 'assets/images/product-placeholder.webp'" alt="Bundle image"
      (error)="handleImageError($event)">

    <div class="d-flex flex-column gap-2 flex-grow-1">

      <h6 class="h6-bold m-0">
        {{bundle.name}}
      </h6>

      <span class="h7-regular">
        Frequency: {{bundle.frequency}}
      </span>

      <button type="button" class="btn btn-outline-secondary w-100" (click)="goToCustomBox(bundle)">
        Customize box
      </button>

    </div>

  </div>
  }

  @if (!bundles().length) {
  <app-empty-message [message]="'Your subscription currently does not include any FarmBox.'"></app-empty-message>
  }

</section>